<template>
  <div>
    <el-dialog
      title="任务列表"
      :visible.sync="dialogVisible"
      width="70%"
      :before-close="handleClose"
    >
      <div class="content">
        <div
          v-for="(item, index) in data"
          :key="item.q_id"
          @click="chooseP(item.q_id)"
          :class="item.q_id == active ? 'isActive' : ''"
          :title="item.title"
        >
          <img src="@/assets/kaiguangreen.png" alt="" v-if="item == active" />
          <div style="display: flex; align-items: center">
            <span>{{ ++index }}、</span>
            <p v-html="item.topic" style="flex: 1"></p>
          </div>
        </div>
        <el-empty description="暂无数据" v-if="!data.length"></el-empty>
        <img src="@/assets/biaoqian.png" class="bq" alt="" />
        <span class="sssw">测试</span>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      active: "",
      data: [],
    };
  },
  methods: {
    show(data, activeItem) {
      this.data = data;
      this.active = activeItem;
      this.dialogVisible = true;
    },
    chooseP(item) {
      this.active = item;
      this.$parent.activeItem = item;
    },
    handleClose(done) {
      done();
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 4px;
  padding-top: 60px;
  box-sizing: border-box;
  position: relative;
  .bq {
    width: 53px;
    height: 45px;
    position: absolute;
    top: 10px;
    left: 10px;
  }
  .sssw {
    position: absolute;
    top: 18px;
    left: 20px;
    font-size: 12px;
    font-weight: bold;
    color: #4579e9;
  }
  p {
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    padding: 5px;
    box-sizing: border-box;
    text-overflow: ellipsis;
    position: relative;
    border-radius: 4px;
    transition: all 0.3s linear;
    &:hover {
      background: #ebebeb;
    }
  }
}
.isActive {
  color: #60a670;
}
:deep(.el-dialog__title) {
  color: #4579e9;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
}
:deep(.el-dialog__headerbtn .el-dialog__close) {
  color: #4579e9;
}
:deep(.el-dialog__body) {
  background: #ebebeb;
  padding: 10px 30px 20px 30px;
  box-sizing: border-box;
  min-height: 600px;
}
</style>
