<template>
  <div class="Ibox" v-loading="boxLoading">
    <div class="topBox">
      <div class="headerBack" @click="backRouter">
        <i class="el-icon-arrow-left"></i>
        {{ e_data.title }}
      </div>
      <div class="timeBox">
        <div class="whiteBox">{{ days }}</div>
        <p>天</p>
        <div class="whiteBox">{{ hours }}</div>
        <p>时</p>
        <div class="whiteBox">{{ minutes }}</div>
        <p>分</p>
        <div class="whiteBox">{{ seconds }}</div>
        <p>秒</p>
      </div>
      <div class="taskListBox" @click="topicsListBtn">
        <i class="el-icon-s-order"></i>
        <p>任务列表</p>
      </div>
      <div class="taskListBox" @click="postPaperBtn">
        <i class="el-icon-s-management"></i>
        <p>提交试卷</p>
      </div>
    </div>
    <div class="content">
      <div class="coLeft" :class="isClose ? 'isClose' : 'isNotClose'">
        <div class="coLeftBox">
          <div class="dtk">答题卡</div>
          <div class="xuxian"></div>
          <div class="datiBox">
            <p>测试</p>
            <div
              class="cardDiv"
              v-for="(item, index) in dataList"
              :key="item.q_id"
              :class="
                item.q_id == activeItem
                  ? 'isActive'
                  : item.problem_state
                  ? 'isDone'
                  : 'isNotDo'
              "
              @click="activeBox(item.q_id)"
            >
              {{ ++index }}
            </div>
            <el-empty description="暂无数据" v-if="!dataList.length"></el-empty>
          </div>
          <div class="downInfo">
            <div class="downInfoBox">
              <div class="isActive"></div>
              当前
            </div>
            <div class="downInfoBox">
              <div class="isNotDo"></div>
              未做
            </div>
            <div class="downInfoBox">
              <div class="isDone"></div>
              已做
            </div>
          </div>
          <div class="maxmin" @click="isClose = !isClose">
            <i class="el-icon-arrow-left"></i>
          </div>
          <div class="maxmin2" @click="isClose = !isClose" v-if="isClose">
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
      <div class="coRight" :style="{ marginLeft: isClose ? '10px' : '0' }">
        <div class="coRightBox" v-if="dataList.length">
          <div class="topTT">
            <img src="@/assets/kaiguan.png" alt="" />
            <p>( {{ index + 1 }} ) {{ dataInfo.type_text }}</p>
            <span>本题:{{ dataInfo.score }}分</span>
          </div>
          <div style="margin: 10px 0" v-html="dataInfo.title"></div>
          <div
            v-if="dataInfo.title != dataInfo.topic_text"
            style="margin: 10px 0"
            v-html="dataInfo.topic"
          ></div>
          <el-radio-group
            v-model="dataInfo.select_answer"
            v-if="dataInfo.type == 1"
          >
            <el-radio
              v-for="(item, index) in dataInfo.select_option"
              :key="index"
              :label="item.label"
              >{{ item.label + "." + item.value }}</el-radio
            >
          </el-radio-group>
          <el-checkbox-group
            v-model="dataInfo.select_answer"
            v-if="dataInfo.type == 2"
          >
            <el-checkbox
              v-for="(item, index) in dataInfo.select_option"
              :key="index"
              :label="item.label"
              >{{ item.label + "." + item.value }}</el-checkbox
            >
          </el-checkbox-group>
          <el-radio-group
            v-model="dataInfo.select_answer"
            v-if="dataInfo.type == 3"
          >
            <el-radio label="0">错误</el-radio>
            <el-radio label="1">正确</el-radio>
          </el-radio-group>
          <el-input
            style="margin-bottom: 20px"
            type="textarea"
            :rows="4"
            placeholder="请输入内容"
            v-model="dataInfo.select_answer"
            v-if="dataInfo.type == 4"
          >
          </el-input>
          <el-upload
            v-if="dataInfo.type == 4"
            class="upload-demo"
            drag
            action=""
            :on-change="handleChange"
            :on-remove="handleRemove"
            :file-list="fileList"
            :auto-upload="false"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              <em> 点击上传试卷文件</em>
              <p style="margin-top: 10px">
                支持各类文档、图片、代码、压缩包格式
              </p>
            </div>
          </el-upload>
          <div class="btnBox">
            <el-button
              type="primary"
              size="medium"
              @click="submitBtn(-1)"
              :disabled="dataInfo.upDisabledType"
              >上一题</el-button
            >
            <el-button
              type="primary"
              size="medium"
              @click="submitBtn(1)"
              :disabled="dataInfo.downDisabledType"
              >下一题</el-button
            >
            <el-button type="success" size="medium" @click="saveBtn"
              >保存数据</el-button
            >
          </div>
        </div>
        <el-empty description="暂无数据" v-else></el-empty>
      </div>
    </div>
    <topicsList ref="topicsList" />
    <progressDialog ref="progressDialog" />
  </div>
</template>
<script>
import * as http from "@/api/order.js";
import topicsList from "@/components/topicsList.vue";
import progressDialog from "@/components/progressDialog.vue";
import { client, headers, getFileNameUUID, rules } from "@/utils/oss.js";
export default {
  data() {
    return {
      countdownDate: 0, // 设置倒计时的目标日期
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      activeItem: "",
      isClose: false,
      radio: "",
      id: this.$route.query.id,
      e_data: {},
      dataList: [],
      fileList: [],
      delId: [],
      idList: [],
      dataInfo: {},
      index: "",
      boxLoading: false,
    };
  },
  components: { topicsList, progressDialog },
  watch: {
    activeItem: {
      handler(val) {
        this.fileList = [];
        this.delId = [];
        let index = this.dataList.findIndex((item) => {
          return item.q_id == val;
        });
        this.index = index;
        if (index >= 0) {
          this.dataInfo = this.dataList[index];

          if (this.dataInfo.files.length) {
            this.fileList = this.dataInfo.files.map((item) => {
              return {
                name: item.name,
                url: item.path,
                id: item.id,
              };
            });
            this.dataInfo.files.forEach((item) => {
              this.idList.push(item.id);
            });
          } else {
            this.fileList = [];
          }
          this.dataInfo.upDisabledType = index == 0 ? true : false;
          this.dataInfo.downDisabledType =
            index + 1 == this.dataList.length ? true : false;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    postPaperBtn() {
      this.saveBtnNoMessage();
      this.$confirm("确认提交当前试卷吗？")
        .then(() => {
          http.postPaper({ e_id: this.id }).then((res) => {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.$router.go(-1);
            this.getList2();
          });
        })
        .catch((_) => {});
    },
    handleChange(file) {
      let nunmber = new Date().getTime();
      var fileName = "学生作业/" + nunmber + file.name;
      let that = this;
      client()
        .multipartUpload(fileName, file.raw, {
          progress: function (percentage, cpt) {
            if (!that.closePage) {
              that.$refs.progressDialog.show(
                Math.floor(percentage * 100),
                file,
                nunmber
              );
            } else {
              that.closePage = false;
              client.cancel();
            }
          },
        })
        .then((res) => {});
      return;
    },
    handleRemove(file) {
      let index = this.fileList.findIndex((item) => {
        return item.name == file.name;
      });
      this.delId.push(this.fileList[index].id);
      this.fileList.splice(index, 1);
      let index2 = this.fileList.findIndex((item) => {
        return item.id == file.id;
      });
      this.idList.splice(index2, 1);
    },
    getList() {
      this.boxLoading = true;
      http.questionsList({ id: this.id }).then((res) => {
        this.e_data = res.e_data;
        if (res.data.length) {
          this.dataList = res.data;
          this.activeItem = res.data[0].q_id;
          this.dataInfo = this.dataList[0];
        }
        this.countdownDate = res.e_data.end_time;
        this.startCountdown();
        this.boxLoading = false;
      });
    },
    getList2() {
      http.questionsList({ id: this.id }).then((res) => {
        this.$set(this, "dataList", []);
        this.$set(this, "dataList", res.data);
        this.$set(this, "dataInfo", res.data[this.index]);
        this.$set(this, "activeItem", res.data[this.index].q_id);
        // this.dataList = res.data;
        if (this.index == 0) {
          this.dataInfo.upDisabledType = true;
        }
        if (this.index == this.dataList.length - 1) {
          this.dataInfo.downDisabledType = true;
        }
      });
    },

    submitBtn(num) {
      let index = this.dataList.findIndex((item) => {
        return item.q_id == this.activeItem;
      });

      this.activeItem = this.dataList[index + num].q_id;
      let data = this.dataList[index].select_answer;
      if (
        (data != "" && data != null) ||
        (Array.isArray(data) && data.length)
      ) {
        let params = {
          e_id: this.id,
          a_id: this.dataList[index].a_id,
          q_id: this.dataList[index].q_id,
          answer: Array.isArray(data) ? data.sort() : data,
          fileList: this.dataList[index].type == 4 ? this.fileList : [],
          delId: this.dataList[index].type == 4 ? this.delId : [],
        };
        http.answerSheet({ ...params }).then((res) => {
          this.getList2();
          // this.data[index].problem_state = true;
        });
      }
      this.getList2();
    },
    saveBtn() {
      let index = this.dataList.findIndex((item) => {
        return item.q_id == this.activeItem;
      });
      let data = this.dataList[index].select_answer;
      console.log(data);
      if (
        (data != "" && data != null) ||
        this.fileList.length ||
        (Array.isArray(data) && data.length)
      ) {
        let params = {
          e_id: this.id,
          a_id: this.dataList[index].a_id,
          q_id: this.dataList[index].q_id,
          answer: Array.isArray(data)
            ? data.sort()
            : data
            ? data
            : "答案见附件",
          fileList: this.dataList[index].type == 4 ? this.fileList : [],
          delId: this.dataList[index].type == 4 ? this.delId : [],
        };
        http.answerSheet({ ...params }).then((res) => {
          this.dataList[index].problem_state = 1;
          this.$message({
            type: "success",
            message: res.msg,
          });
        });
      } else {
        this.$message({
          type: "info",
          message: "请先填写答案再保存数据",
        });
      }
    },
    saveBtnNoMessage() {
      let index = this.dataList.findIndex((item) => {
        return item.q_id == this.activeItem;
      });
      let data = this.dataList[index].select_answer;
      if (
        (data != "" && data != null) ||
        (Array.isArray(data) && data.length)
      ) {
        let params = {
          e_id: this.id,
          a_id: this.dataList[index].a_id,
          q_id: this.dataList[index].q_id,
          answer: Array.isArray(data) ? data.sort() : data,
          fileList: this.dataList[index].type == 4 ? this.fileList : [],
          delId: this.dataList[index].type == 4 ? this.delId : [],
        };
        http.answerSheet({ ...params }).then((res) => {
          this.dataList[index].problem_state = 1;
        });
      }
    },
    startCountdown() {
      setInterval(() => {
        const now = new Date().getTime();
        const distance = this.countdownDate - now;
        if (distance <= 0) {
          return;
        }
        this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
        this.hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
      }, 1000);
    },
    topicsListBtn() {
      this.$refs.topicsList.show(this.dataList, this.activeItem);
    },
    backRouter() {
      this.$router.go(-1);
    },
    activeBox(item) {
      let index = this.dataList.findIndex((items) => {
        return items.q_id == item;
      });
      http.questionsList({ id: this.id }).then((res) => {
        this.dataList = res.data;
        this.dataInfo = res.data[index];
        this.activeItem = res.data[index].q_id;
        if (index == 0) {
          console.log(this.dataInfo);
          this.dataInfo.upDisabledType = true;
        }
        if (index == this.dataList.length - 1) {
          this.dataInfo.downDisabledType = true;
        }
      });
      this.saveBtnNoMessage();
      this.activeItem = item;
    },
  },
};
</script>
<style lang="scss" scoped>
.Ibox {
  width: 100vw;
  height: 100vh;
  background: #ebebeb;
  .topBox {
    width: 100%;
    height: 50px;
    background: #4579e9;
    color: #fff;
    padding: 9px 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .headerBack {
      width: 220px;
      height: 100%;
      border: 1px solid #fff;
      border-radius: 4px;
      display: flex;
      align-items: center;
      cursor: pointer;
      i {
        font-size: 22px;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 4px;
        box-sizing: border-box;
        border-right: 1px solid #fff;
        margin-right: 10px;
      }
      &:hover {
        background: #fff;
        color: #4579e9;
        i {
          border-color: #4579e9;
        }
      }
    }
    .timeBox {
      display: flex;
      align-items: center;
      margin: 0 20px;
      font-size: 14px;
      .whiteBox {
        background: #fff;
        opacity: 0.96;
        border-radius: 4px;
        padding: 3px 6px 3px 6px;
        color: #f3547d;
        font-size: 16px;
        font-weight: 700;
      }
      p {
        margin: 0 4px;
      }
    }
    .taskListBox {
      display: flex;
      align-items: center;
      align-items: center;
      font-size: 14px;
      margin: 0 20px;
      cursor: pointer;
      i {
        font-size: 18px;
        margin-right: 4px;
      }
    }
  }
  .content {
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    .coLeft {
      width: 260px;
      height: 100%;
      overflow: auto;
      transition: all 0.3s linear;
      .coLeftBox {
        width: 230px;
        height: calc(100% - 20px);
        background: #fff;
        margin: 10px 12px 0 10px;
        border-radius: 4px;
        position: relative;

        border-top: 1px solid transparent;
        .dtk {
          height: 32px;
          background: #6fdc89;
          color: #fff;
          font-size: 14px;
          text-align: center;
          line-height: 32px;
          width: calc(100% - 30px);
          border-radius: 6px;
          margin: 10px 0 10px 15px;
        }
        .xuxian {
          width: 100%;
          height: 1px;
          border-bottom: 1px dashed #ebebeb;
          position: relative;
          &::before {
            content: "";
            display: block;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: #ebebeb;
            position: absolute;
            left: -8px;
            top: 50%;
            transform: translateY(-44%);
          }
          &::after {
            content: "";
            display: block;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: #ebebeb;
            position: absolute;
            right: -8px;
            top: 50%;
            transform: translateY(-44%);
          }
        }
        .datiBox {
          width: 100%;
          height: calc(100% - 100px);
          padding: 10px;
          box-sizing: border-box;
          font-size: 12px;
          p {
            margin-bottom: 10px;
          }
          .cardDiv {
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            float: left;
            cursor: pointer;
            margin: 0 10px 10px 0;
            transition: all 0.2s linear;
          }
          .isActive {
            background: #6fdc89;
            border: 1px solid #6fdc89;
            border-radius: 4px;
            color: #fff;
          }
          .isNotDo {
            background: #fff;
            border: 1px solid #e3e3e3;
            border-radius: 4px;
          }
          .isDone {
            background: hsla(0, 0%, 40%, 0.1);
            border: 1px solid #dbdbdb;
            border-radius: 4px;
          }
        }
        .downInfo {
          display: flex;
          align-items: center;
          padding: 15px 10px 10px 10px;
          box-sizing: border-box;
          border-top: 1px solid #ebebeb;
          .downInfoBox {
            display: flex;
            align-items: center;
            margin-right: 10px;
            div {
              width: 14px;
              height: 14px;
              border-radius: 4px;

              margin-right: 2px;
            }
            .isActive {
              background: #6fdc89;
            }
            .isNotDo {
              background: #fff;
              border: 1px solid #e3e3e3;
            }
            .isDone {
              background: hsla(0, 0%, 40%, 0.1);
              border: 1px solid #dbdbdb;
            }
            font-size: 12px;
            color: rgb(153, 153, 153);
          }
        }
        .maxmin {
          width: 12px;
          height: 90px;
          padding: 0;
          position: absolute;
          top: calc(50% - 50px);
          right: -12px;
          background: #bfbfbf;
          border-radius: 0 5px 5px 0;
          text-align: center;
          line-height: 90px;
          color: #fff;
          cursor: pointer;
          &:hover {
            background: #858585;
          }
        }
        .maxmin2 {
          width: 12px;
          height: 90px;
          padding: 0;
          position: fixed;
          top: calc(50% - 50px);
          left: 0px;
          background: #bfbfbf;
          border-radius: 0 5px 5px 0;
          text-align: center;
          line-height: 90px;
          color: #fff;
          cursor: pointer;
          &:hover {
            background: #858585;
          }
        }
      }
    }
    .isClose {
      width: 0px !important;
    }
    .isNotClose {
      width: 260px;
    }
    .coRight {
      flex: 1;
      padding: 10px;
      box-sizing: border-box;
      .coRightBox {
        width: 100%;
        height: 100%;
        background: #fff;
        border-radius: 4px;
        padding: 10px;
        box-sizing: border-box;
        .topTT {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          p {
            font-size: 14px;
            color: #333;
            margin-left: 4px;
            font-weight: 700;
          }
          span {
            color: #ff5900;
            font-size: 12px;
            font-weight: 400;
            margin-left: 8px;
            padding: 4px 10px;
            background: rgba(255, 125, 0, 0.12);
            border-radius: 6px;
          }
        }
        .btnBox {
          width: 30%;
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
        }
      }
    }
  }
}
:deep(.el-radio),
:deep(.el-checkbox) {
  display: block;
  margin: 14px 0;
}
:deep(.el-upload),
:deep(.el-upload-dragger) {
  width: 100%;
}
::v-deep .el-upload-list__item .el-icon-upload-success {
  display: none;
}
::v-deep .el-upload-list__item {
  padding: 20px 10px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #dadce0;
  font-size: 20px;
  margin-bottom: 20px;
}
::v-deep .el-upload-list__item .el-icon-close {
  position: absolute;
  top: 40%;
  right: 20px;
}
</style>
